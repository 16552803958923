import React from "react";
import "./header.css";
import logo from "../images/logo.png";
const Header: React.FC = () => {
  return (
    <nav className="App-header">
      <a href="https://twobirds.io">
        <img className="App-logo" src={logo} alt="Logo" />
      </a>
    </nav>
  );
};

export default Header;
