import React from "react";
import "./loading.css";

export default function LoadingScreen() {
  return (
    <div id="page">
      <div id="phrase_box">
        <svg width="100%" height="100%">
          <defs>
            <mask
              id="mask"
              maskUnits="userSpaceOnUse"
              maskContentUnits="userSpaceOnUse"
            >
              <linearGradient
                id="linearGradient"
                gradientUnits="objectBoundingBox"
                x2="0"
                y2="1"
              >
                <stop stopColor="white" stopOpacity="0" offset="0%"></stop>
                <stop stopColor="white" stopOpacity="1" offset="30%"></stop>
                <stop stopColor="white" stopOpacity="1" offset="70%"></stop>
                <stop stopColor="white" stopOpacity="0" offset="100%"></stop>
              </linearGradient>
              <rect
                width="100%"
                height="100%"
                fill="url(#linearGradient)"
              ></rect>
            </mask>
          </defs>
          <g width="100%" height="100%">
            <g id="phrases">
              <text fill="white" x="50" y="30" fontSize="14" fontFamily="Arial">
                Stærð...
              </text>
              <g transform="translate(10 10) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-0"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-0"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text fill="white" x="50" y="80" fontSize="14" fontFamily="Arial">
                Framboð eigna...
              </text>
              <g transform="translate(10 60) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-1"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-1"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="130"
                fontSize="14"
                fontFamily="Arial"
              >
                Sérstaða staðsetningar...
              </text>
              <g transform="translate(10 110) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-2"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-2"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="180"
                fontSize="14"
                fontFamily="Arial"
              >
                Verðþróun eigna á markaði...
              </text>
              <g transform="translate(10 160) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-3"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-3"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="230"
                fontSize="14"
                fontFamily="Arial"
              >
                Nýlega seldar eignir...
              </text>
              <g transform="translate(10 210) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-4"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-4"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="280"
                fontSize="14"
                fontFamily="Arial"
              >
                Fjarlægð í þjónustu...
              </text>
              <g transform="translate(10 260) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-5"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-5"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="330"
                fontSize="14"
                fontFamily="Arial"
              >
                Vinsældir hverfisins...
              </text>
              <g transform="translate(10 310) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-6"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-6"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="380"
                fontSize="14"
                fontFamily="Arial"
              >
                Þróun á fasteignamarkaði...
              </text>
              <g transform="translate(10 360) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-7"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-7"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="430"
                fontSize="14"
                fontFamily="Arial"
              >
                Herbergjafjöldi...
              </text>
              <g transform="translate(10 410) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-8"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-8"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
              <text
                fill="white"
                x="50"
                y="480"
                fontSize="14"
                fontFamily="Arial"
              >
                Eiginleikar eignar...
              </text>
              <g transform="translate(10 460) scale(.9)">
                <circle
                  id="loadingCheckCircleSVG-9"
                  fill="rgba(255,255,255,0)"
                  cx="16"
                  cy="16"
                  r="15"
                ></circle>
                <polygon
                  points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708 "
                  fill="rgba(255,255,255,1)"
                  id="loadingCheckSVG-9"
                ></polygon>
                <path
                  d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
                  fill="white"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div id="footer">
        <div id="logo"></div>
      </div>
    </div>
  );
}
