import React, { useState } from "react";
import DataTable from "react-data-table-component";

const nfObject = new Intl.NumberFormat("de-DE");

const columns = [
  {
    name: <p className="category">Heimilisfang</p>,
    selector: (row: any) => row.address,
    sortable: true,
    grow: 2,
    cell: (row: any) => (
      <div>
        {row.address}
      </div>
    )
  },
  {
    name: <p className="category">Stærð</p>,
    selector: (row: any) => row.size,
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>
          {parseFloat(row.size)} m<i className="sup">2</i>
        </div>
      </div>
    )
  },
  {
    name: <p className="category">Ásett</p>,
    selector: (row: any) => row.setPriceMax,
    sortable: true,
    cell: (i: any) => (
      <div>
        <div>
          {i.setPriceMax
            ? i.setPriceMin
              ? (i.setPriceMax !== i.setPriceMin ? i.setPriceMax + "-" + i.setPriceMin: i.setPriceMax)
              : i.setPriceMax
            : "-"}
        </div>
      </div>
    )
  },
  {
    name: (
      <p className="category">
        Verð m<i className="sup">2</i>
      </p>
    ),
    selector: (row: any) => row.pricePer,
    sortable: true,
    grow: 1.3,
    cell: (row: any) => (
      <div>
        <div>{row.pricePer ? nfObject.format(row.pricePer) : "-"}</div>
      </div>
    )
  },
  {
    name: <p className="category">Tegund</p>,
    selector: (row: any) => row.type,
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.type}</div>
      </div>
    )
  },
  {
    name: <p className="category">Byggt</p>,
    selector: (row: any) => row.year,
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.year}</div>
      </div>
    )
  },
  {
    name: <p className="category">Í sölu</p>,
    selector: (row: any) => row.days,
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.days ? row.days + " daga" : "-"}</div>
      </div>
    )
  },
  {
    name: <p className="category">Skráð</p>,
    selector: (row: any) => row.set,
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.set ? row.set.substr(8,2) + '.' +row.set.substr(5,2) + '.' + row.set.substr(0,4) : ""}</div>
      </div>
    )
  }
];

export default function Table(props: {
  onSale_rad: any[] | null;
  onSale_hve: any[] | null;
  onSale_zip: any[] | null;
  zip?: number;
  hverfi?: string;
}) {
  const { onSale_hve, onSale_rad, onSale_zip, hverfi, zip } = props;
  const [onSaleC, setOnSaleC] = useState<string>("radius");
  const [onSale, setOnSale] = useState<Array<any> | null>(onSale_rad);
  
  function changeChoice(a: string) {
    if (a !== onSaleC) {
      setOnSaleC(a);
      if (a === "radius") {
        setOnSale(onSale_rad);
      } else if (a === "hverfi") {
        setOnSale(onSale_hve);
      } else {
        setOnSale(onSale_zip);
      }
    }
  }
  return (
    <React.Fragment>
      <p className="Banner-title">
        Sambærilegar eignir til sölu{" "}
        {onSaleC === "radius"
          ? "í nálægum radíus"
          : onSaleC === "hverfi"
          ? "í hverfinu (" + (hverfi ? hverfi : "-") + ")"
          : "í póstnúmeri (" + (zip ? zip : "-") + ")"}
      </p>
      <div className="Buttons-choice">
        <button
          className={
            onSaleC === "radius" ? "Button-choice toggled" : "Button-choice"
          }
          onClick={() => changeChoice("radius")}
        >
          Radíus
        </button>
        <button
          className={
            onSaleC === "hverfi" ? "Button-choice toggled" : "Button-choice"
          }
          onClick={() => changeChoice("hverfi")}
        >
          Hverfi
        </button>
        <button
          className={
            onSaleC === "postnumer" ? "Button-choice toggled" : "Button-choice"
          }
          onClick={() => changeChoice("postnumer")}
        >
          Póstnúmer
        </button>
      </div>
      <div className="App-banner">
        {onSale && (
          <DataTable data={onSale} columns={columns as any} noHeader={true} />
        )}
      </div>
    </React.Fragment>
  );
}
