import { House, SoldProp, onSaleProp } from "./interfaces";
const baseurl: string | undefined = 'https://lanaæreiknir.aurbjorg.is/api/appraisals';
const minurl: string | undefined = 'https://lanareiknir.aurbjorg.is/api/appraisals/';
const extraurl: string | undefined = 'https://lanareiknir.aurbjorg.is/api/';
const url = "https://platform.aurbjorg.is/real-estate-gw/realEstate/propertyAssessmentBreakdown/";


async function getMainData(id: string) {
  const response = await fetch(`${url}${id}`);
  const data = await response.json();
  return data;
}

async function getHouse(token: string): Promise<House | null> {
  var newLoc = `general/${token}`;
  if (minurl) {
    const url = new URL(newLoc, minurl);
    const response = await fetch(url.href);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }

    return null;
  }
  return null;
}

async function getSold(token: string, loc: string): Promise<SoldProp[] | null> {
  var newLoc = baseurl + '';
  if (loc === "hverfi") newLoc += `/sold/neighbourhood/${token}`;
  else if (loc === "radius") newLoc += `/sold/radius/${token}`;
  else newLoc += `/sold/postcode/${token}`;
  if (baseurl) {
    const url = new URL(newLoc);
    const response = await fetch(url.href);
    if (response.status === 200) {
      const data = await response.json();
      return data.eignir;
    }
    return null;
  }
  return null;
}

async function getOnSale(
  token: string,
  loc: string
): Promise<onSaleProp[] | null> {
  var newLoc = baseurl + '';
  if (loc === "hverfi") newLoc += `/onsale/neighbourhood/${token}`;
  else if (loc === "radius") newLoc += `/onsale/radius/${token}`;
  else newLoc += `/onsale/postcode/${token}`;
  if (baseurl) {
     const url = new URL(newLoc);
    const response = await fetch(url.href);
    if (response.status === 200) {
      const data = await response.json();
      return data.eignir;
    } 
    return null;
  }
  return null;
}

async function getNeighbor(token: string): Promise<any | null> {
  if (baseurl) {
    var newLoc = baseurl  + `/neighbourhood/${token}`;
    const url = new URL(newLoc);
    const response = await fetch(url.href);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }

    return null;
  }
  return null;
}

async function getDistance(token: string): Promise<any | null> {  
  var newLoc = `distances/token/${token}`;
  if (extraurl) {
    const url = new URL(newLoc, extraurl);
    const response = await fetch(url.href);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }

    return null;
  } 
  return null;
}

export { getHouse, getSold, getOnSale, getNeighbor, getDistance, getMainData };
