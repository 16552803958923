import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import Home from './routes/home';

function App() {
  return (
    <HelmetProvider>
      <Helmet defaultTitle="TwoBirds" titleTemplate="%s - TwoBirds" />

      <Routes>
        <Route path="/verdvisir/view/:id" element={<HomeWrapper />} />
        <Route path="*" element={<RedirectToExternal />} />
      </Routes>
    </HelmetProvider>
  );
}

// Wrapper component to pass the id parameter to Home
function HomeWrapper() {
  const { id } = useParams();
  return <Home id={id} />;
}

// New component to handle redirection
function RedirectToExternal() {
  React.useEffect(() => {
    window.location.href = 'https://twobirds.io/';
  }, []);

  return null;
}

export default App;