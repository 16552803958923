import React from "react";
import "../index";
import { Imod } from "../api/interfaces";

export default function Banner(props: { eign: Imod | null | undefined }) {
  const { eign } = props;

  if (eign) {
    const nfObject = new Intl.NumberFormat("de-DE");
    const pm = nfObject.format(Math.round(eign.price / eign.size));

    const test = (
      <div>
        <input type="radio" name="tabs" id="tab1" defaultChecked />
        <input type="radio" name="tabs" id="tab2" />
        <nav className="banner-navbar">
        <label className="nav-link-eign first" htmlFor="tab1" id="eign_label">
          Eign
        </label>
        <label className="nav-link-eign second" htmlFor="tab2" id="um_label">
          Um
        </label>
        </nav>
        <div className="tab content2">
          <div className="eign-more">
            <ul className="eign-list">
              <li className="category">Tegund</li>
              <li className="category">Stærð</li>
              <li className="category">Íbúðarnúmer</li>
              <li className="category">Byggt</li>
            </ul>
            <ul className="eign-list">
              <li>{eign?.type}</li>
              <li>
                {eign?.size} m<i className="sup">2</i>
              </li>
              <li>{eign?.number}</li>
              <li>{eign?.year}</li>
            </ul>
          </div>
        </div>
        <div className="tab content1">
          <div className="eign-price ">
            <p className="eign-price-title">Áætlað markaðsverð</p>
            <div className="eign-price-small">
              <p className="eign-price-price">
                {nfObject.format(eign?.price)} kr.
              </p>
              <p className="eign-price-m">
                {pm} kr/m<i className="sup">2</i>
              </p>
              <h3>Verðbil</h3>
              <p>
                {nfObject.format(eign?.rangeMin)} kr. -{" "}
                {nfObject.format(eign?.rangeMax)} kr.
              </p>
            </div>
            <p className="small-text">
              Áætlað markaðsvirði og verðbil byggir á raungögnum og
              markaðsgögnum sem túlkuð eru með notkun gervigreindar. Tölurnar
              sem þarna koma fram sýna áætlun um virði eignar og endurspegla
              ekki alltaf raunvirði. Við hvetjum kaupendur og seljendur til að
              leita til fagaðila til að fá skýrari mynd af raunvirði eignar.
            </p>
          </div>
        </div>
      </div>
    );

    const together = (
      <React.Fragment>
        <div className="nav">
          <div className="both-charts-text">
            <h2 className="Banner-title-big">Eign</h2>
            <h2 className="Banner-title-big">Um</h2>
          </div>
        </div>
        <div className="both-charts">
          <div className="eign-more">
            <div className="eign-price">
              <h3 className="eign-price-title">Áætlað markaðsverð</h3>
              <div className="eign-price-small">
                <p className="eign-price-price">
                  {nfObject.format(eign?.price)} kr.
                </p>
                <p className="eign-price-m">
                  {pm} kr/m<i className="sup">2</i>
                </p>
                <h3>Verðbil</h3>
                <p>
                  {nfObject.format(eign?.rangeMin)} kr. -{" "}
                  {nfObject.format(eign?.rangeMax)} kr.
                </p>
              </div>
              <p className="small-text">
                Áætlað markaðsvirði og verðbil byggir á raungögnum og
                markaðsgögnum sem túlkuð eru með notkun gervigreindar. Tölurnar
                sem þarna koma fram sýna áætlun um virði eignar og endurspegla
                ekki alltaf raunvirði. Við hvetjum kaupendur og seljendur til að
                leita til fagaðila til að fá skýrari mynd af raunvirði eignar.
              </p>
            </div>
          </div>
          <div className="eign-more">
            <ul className="eign-list">
              <li className="category">Tegund</li>
              <li className="category">Stærð</li>
              <li className="category">Íbúðarnúmer</li>
              <li className="category">Byggt</li>
            </ul>
            <ul className="eign-list">
              <li>{eign?.type}</li>
              <li>
                {eign?.size} m<i className="sup">2</i>
              </li>
              <li>{eign?.number}</li>
              <li>{eign?.year}</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
    const win = test;

    return (
      <React.Fragment>
        <div className="App-banner">
          <div className="nav-link">
            <div className="mobile">{win}</div>
            <div className="desktop">{together}</div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
}
